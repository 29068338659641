import { datadogLogs } from '@datadog/browser-logs'

let ENV = document.querySelector("meta[name='env']");

if(ENV != null) {
  datadogLogs.init({
    clientToken: 'pub99913b544a78550a3ab06ba93cf1f287',
    site: 'datadoghq.com',
    service: 'titan',
    env: ENV.getAttribute('content'),
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    useSecureSessionCookie: true
  })
}
